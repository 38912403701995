import * as React from "react"
import PropTypes from "prop-types"
import Emoji from "../../images/emoji.png";
import Site from "../../images/site.svg";
import Header from "./header";


const EndWrap = (props) => {
    const { date, children } = props;

    return (
        <>
            <div className="flex-none">
                <Header date={date} conf={null}/>
            </div>

            <div className={`flex-1 mt-32 font-medium text-10`}>
                <p className={`text-14 mb-24 leading-15 text-borange`}>Merci de votre visite&nbsp;!</p>
                {children}
            </div>
        </>
    )
}


export const EndOfFair = (props) => {
    const { date } = props;

    return (
        <EndWrap date={date}>
            <p className="leading-none">Retrouvez-nous sur<br/> le site&nbsp;:</p>
            <a className="inline-flex flex-row items-center mt-12 block rounded-3xl bg-borange text-white text-2xl whitespace-nowrap" href="src/components/shared/end" target="_blank" rel="noreferrer" style={{ padding: "7px 23px 9px" }}>
                <img className={`block w-8 mr-2`} src={Site} alt="Website"/>
                <span>www.bouygues-innovation.com</span>
            </a>
        </EndWrap>
    )
}

export const EndOfDay = (props) => {
    const { date } = props;

    return (
        <EndWrap date={date}>
            <p className="font-bryant leading-12">À demain pour de nouvelles conférences</p>
            <img className={`block w-10 mt-4`} src={Emoji} alt="Emoji"/>
        </EndWrap>
    )
}


EndOfFair.propTypes = {
    date: PropTypes.string.isRequired,
}
EndOfDay.propTypes = {
    date: PropTypes.string.isRequired,
}