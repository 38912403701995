import PropTypes from "prop-types";
import * as React from "react";


const Host = (props) => {
    const { host } = props;

    return (
        <div className="border border-borange rounded-3xl py-4 px-6 mt-auto text-5.85 leading-none">
            <span className="block mb-2">Avec</span>
            <span className="block text-11 font-bryant font-medium text-borange">{ host.name }</span>
            <span className="block mt-2">{ host.company }</span>
        </div>
    )
}

Host.propTypes = {
    host: PropTypes.object.isRequired,
};



export default Host
