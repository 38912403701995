import Logo from "../../images/logo.svg";
import * as React from "react";
import Time from "../../images/time.svg";

const Header = (props) => {
    const { conf, date } = props;
    let hours = null;
    if (conf)
        hours = `${conf.startHour}:${conf.startMinutes} - ${conf.endHour}:${conf.endMinutes}`;

    return (
        <div className={`flex flex-row items-center justify-between`}>
            <div className={`flex-none mr-5`}>
                <img className={`max-w-logo`} src={Logo} alt="Bouygues"/>
            </div>

            <div className={`flex-1 leading-4 text-right`}>
                <span className={`block capi ${conf ? 'text-lg' : 'text-5.85'}`}>{date}</span>
                { conf && (
                    <>
                        <div className={"inline-flex flex-row items-center justify-between text-5.75"}>
                            <img className={"flex-none mr-1 mt-1"} src={Time} alt="Horaires"/> <span className={"flex-1"}>{hours}</span>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default Header;