import React, { useEffect, useRef } from 'react';
import { navigate } from "gatsby";
import { gsap } from 'gsap';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import {
    getCurrentTime,
    getRemainingDayConfs,
    getFormatedDay,
    getIsOver,
    printDatas,
    getAutoplaySpeed,
    getJuicerSpeed,
    fadeIn,
    fadeOut,
    getOngoing,
    flattenConfs, getIncoming
} from "../../helpers/helpers";

import Layout from "../layout/layout"
import Seo from "../layout/seo"
import Juicer from "./juicer";
import Conf from "../shared/conf";
import { EndOfDay, EndOfFair } from "../shared/end";

gsap.registerPlugin(ScrollToPlugin);


const scrollJuicer = (elem, callback) => {
    gsap.to(elem, {
        duration: 2.5,
        ease: "sine.inOut",
        scrollTo: {
            y: elem.scrollTop + window.innerHeight / 1.5,
        },
        onComplete: callback
    });
}

const Index = (props) => {
    const { pageContext: { data: pageContextData }, location: { search } } = props;

    let delayedCalls = null;
    let currentDate = getCurrentTime(search);
    const conferencesDays = pageContextData;
    const allConfs = flattenConfs(conferencesDays);

    const juicerRef = useRef();
    const mainRef = useRef();
    const juicerTimer = getJuicerSpeed(search, 15);

    let ongoing = getOngoing(allConfs, currentDate);
    let incoming = getIncoming(allConfs, currentDate);
    let remaining = getRemainingDayConfs(conferencesDays, currentDate);
    let isOver = getIsOver(conferencesDays, currentDate);

    const initialConf = ongoing;

    useEffect(() => {
        printDatas(search, conferencesDays, currentDate);
        fadeIn(mainRef.current, loopHandler);

        return () => { delayedCalls.kill(); }
    }, []);

    const repeat = (iterations, onComplete) => {
        if (iterations > 0) {
            const callback = () => {
                updateData();

                if ((!isOver && !ongoing) && (incoming && remaining?.length > 0)) {
                    onComplete = () => delayedCalls = gsap.delayedCall(getAutoplaySpeed(search, 15), () => {
                        fadeOut(mainRef.current, () => navigate(`/calendrier/${search}`));
                    });
                    repeat(0, onComplete);
                } else if (initialConf !== ongoing) {
                    repeat(0, onComplete);
                } else {
                    repeat(iterations - 1, onComplete);
                }
            }

            delayedCalls = gsap.delayedCall(juicerTimer, () => {
                scrollJuicer(juicerRef.current, callback);
            });
        } else {
            onComplete();
        }
    }

    const loop = () => {
        const onComplete = () => delayedCalls = gsap.delayedCall(getAutoplaySpeed(search, 15), () => {
            fadeOut(mainRef.current, () => window.location.reload());
        });
        repeat(19, onComplete);
    }

    const loopIncoming = () => {
        const onComplete = () => delayedCalls = gsap.delayedCall(getAutoplaySpeed(search, 15), () => {
            fadeOut(mainRef.current, () => navigate(`/calendrier/${search}`));
        });
        repeat(1, onComplete);
    }

    const updateData = () => {
        currentDate = getCurrentTime(search);
        ongoing = getOngoing(allConfs, currentDate);
        incoming = getIncoming(allConfs, currentDate);
        remaining = getRemainingDayConfs(conferencesDays, currentDate);
        isOver = getIsOver(conferencesDays, currentDate);
    }

    const loopHandler = () => {
        updateData();

        if (isOver || ongoing || (remaining?.length === 0 && !isOver)) {
            loop();
        } else if (incoming && remaining?.length > 0) {
            loopIncoming();
        } else {
            loop();
        }
    }

    return (
        <Layout>
            <Seo title="Aujourd'hui à Vivatech"/>

            { typeof window !== "undefined" && (
                <div ref={mainRef} className={`flex flex-row h-full space-x-10`} style={{ opacity: 0, visibility: "hidden" }}>
                    <div className={`w-2/4 py-7.5 flex flex-col`}>

                        {(ongoing || (incoming && remaining?.length > 0)) && (
                            <Conf conf={ongoing ?? incoming} date={getFormatedDay(currentDate)}/>
                        )}

                        {(!isOver && (!remaining || (remaining?.length === 0))) && (
                            <EndOfDay date={getFormatedDay(currentDate)}/>
                        )}

                        {isOver && (
                            <EndOfFair date={getFormatedDay(currentDate)}/>
                        )}

                    </div>

                    <div className={`w-2/4 juicer-wrap`}>
                        <Juicer juicerRef={juicerRef}/>
                    </div>
                </div>
            )}
        </Layout>
    )
}

export default Index
