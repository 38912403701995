import PropTypes from "prop-types";
import * as React from "react";
import { useState, useRef, useEffect, useLayoutEffect } from "react";


const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        let id = setInterval(() => {
            savedCallback.current();
        }, delay);
        return () => clearInterval(id);
    }, [delay]);
}


const sliderSpeed = 10000;

const Slider = (props) => {
    const { conf } = props;
    const content = conf.speakers ? conf.speakers : (conf.innovations ?? conf.innovations);
    const refs = useRef([]);
    const [ slide, setSlide ] = useState(1);
    const [ maxHeight, setMaxHeight ] = useState(0);

    useInterval(() => {
        if (slide < content.length) {
            setSlide(slide + 1);
        } else {
            setSlide(1);
        }
    }, sliderSpeed);

    useLayoutEffect(() => {
        let heightStatus = 0;

        setTimeout(() => {
            refs.current.map((ref) => {
                const height = ref.clientHeight;
                if (heightStatus < height) { heightStatus = height; }
                return null;
            });

            setMaxHeight(heightStatus);
        }, 250);
    }, []);

    return (
        <div className="my-12">
            { conf.speakers && (
                <span className="block mb-3 tracking-0.075 font-bryant text-3xl font-normal">Intervenant{content.length > 1 ? 's' : ''}</span>
            )}

            <div className="flex flex-row items-end" style={{ minHeight: maxHeight }}>
                <ul className="relative flex-1 mr-5" style={{ minHeight: maxHeight }}>
                    {content.map((speaker, idx) => (
                        <li ref={el => refs.current[idx] = el} className={`absolute bottom-0 w-full ${slide === (idx+1) ? 'opacity-100' : 'opacity-0'} transition-all duration-1000`} key={idx}>
                            <span className="leading-none block tracking-0.075 font-bryant font-medium text-12 leading-12" dangerouslySetInnerHTML={{ __html: speaker.name }}/>
                            <span className="mt-2 font-medium tracking-cat block uppercase">{speaker.company}</span>
                        </li>
                    ))}
                </ul>
                { content.length > 1 && (
                    <div className="flex-none font-medium tracking-cat text-6 leading-none">
                        <span>{slide}</span>/<span>{content.length}</span>
                    </div>
                )}
            </div>
        </div>
    )
}

Slider.propTypes = {
    conf: PropTypes.object.isRequired,
}



export default Slider
