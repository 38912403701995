import * as React from "react"
import PropTypes from "prop-types"
import Category from "./category";
import Slider from "./slider";
import Host from "./host";
import Header from "./header";


export const ConfRow = (props) => {
    const { children, classes } = props;

    return (
        <div className={`font-bryant font-bold tracking-0.275 inline-flex flex-row items-center ${classes}`}>
            {children}
        </div>
    )
}

export const ConfItem = (props) => {
    const { classes, picto, text } = props;

    return (
        <div className={`flex items-center ${classes}`}>
            { picto }
            <span className={`flex-1`}>{ text }</span>
        </div>
    )
}


const Conf = (props) => {
    const { conf, date } = props;

    return (
        <>
            <div className="flex-none">
                <Header conf={conf} date={date}/>
            </div>

            <div className={"flex-1 mt-12 flex flex-col"}>
                <div>
                    <Category conf={conf}/>
                    <h2 className="tracking-0.075 text-borange font-bold text-12 leading-12">{conf.title}</h2>
                </div>

                <Slider conf={conf}/>

                {conf.host && <Host host={conf.host}/>}
            </div>
        </>
    )
}

Conf.propTypes = {
    conf: PropTypes.object.isRequired,
    date: PropTypes.string.isRequired,
}


export default Conf
